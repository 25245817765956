<template>
  <div class="wrpp1">
    <div class="connectmain">
      <div class="connectcontainer">
        <p class="connect">联系我们</p>
        <div  class="maps" id="map"></div>
        <div class="introduction">
          <p>公司地址：浙江省杭州市余杭区仓前街道创鑫时代广场3幢</p>
          <p>邮箱：bd@hexiaoxiang.com</p>
          <p>联系方式：400-163-3686</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {
    this.initMap();
    var that = this;
    that.passYes();
  },

  methods: {
    initMap() {
      console.log("ok");
      this.createMap(); //创建地图
      this.setMapEvent(); //设置地图事件
      this.addMapControl(); //向地图添加控件
      this.addMapOverlay(); //向地图添加覆盖物
    },
    createMap() {
      var map = new BMap.Map("map");
      map.centerAndZoom(new BMap.Point(119.983324, 30.278085), 17);
      window.map = map;
    },
    setMapEvent() {
      map.enableScrollWheelZoom();
      map.enableKeyboard();
      map.enableDragging();
      map.enableDoubleClickZoom();
    },
    addClickHandler(target, window) {
      target.addEventListener("click", function() {
        target.openInfoWindow(window);
      });
    },
    addMapOverlay() {
      var markers = [
        {
          content: "地址：创鑫时代广场3号楼b座703",
          title: "河象网络科技有限公司",
          imageOffset: { width: -46, height: 3 },
          position: { lat: 30.278241, lng: 119.981653 }
        }
      ];
      for (var index = 0; index < markers.length; index++) {
        var point = new BMap.Point(
          markers[index].position.lng,
          markers[index].position.lat
        );
        var marker = new BMap.Marker(point, {
          icon: new BMap.Icon(
            "https://api.map.baidu.com/lbsapi/createmap/images/icon.png",
            new BMap.Size(20, 25),
            {
              imageOffset: new BMap.Size(
                markers[index].imageOffset.width,
                markers[index].imageOffset.height
              )
            }
          )
        });
        var label = new BMap.Label(markers[index].title, {
          offset: new BMap.Size(25, 5)
        });
        var opts = {
          width: 200,
          title: markers[index].title,
          enableMessage: false
        };
        var infoWindow = new BMap.InfoWindow(markers[index].content, opts);
        marker.setLabel(label);
        this.addClickHandler(marker, infoWindow);
        map.addOverlay(marker);
      }
    },
    //向地图添加控件
    addMapControl() {
      var scaleControl = new BMap.ScaleControl({
        anchor: BMAP_ANCHOR_BOTTOM_LEFT
      });
      scaleControl.setUnit(BMAP_UNIT_IMPERIAL);
      map.addControl(scaleControl);
      var navControl = new BMap.NavigationControl({
        anchor: BMAP_ANCHOR_TOP_LEFT,
        type: BMAP_NAVIGATION_CONTROL_LARGE
      });
      map.addControl(navControl);
      var overviewControl = new BMap.OverviewMapControl({
        anchor: BMAP_ANCHOR_BOTTOM_RIGHT,
        isOpen: true
      });
      map.addControl(overviewControl);
    },
    passYes() {
      this.$emit("changeHeader");
    }
  }
};
</script>
<style scoped>
* {
  margin: 0;

  padding: 0;
}
.wrpp1 {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.foot1 {
  display: flex;
  display: -webkit-flex;
  width: 100%;
  background-color: #333333;
  justify-content: center;
}
.introduction {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  align-items: flex-start;
  width: 497px;
  height: 124px;
  margin-top: 52px;
  font-size: 19px;
  font-family: MicrosoftYaHei;
  color: rgba(81, 85, 88, 1);
}
.maps {
  background-image: url("https://xcx.alicdn2.hexiaoxiang.com/web_static/map.png");
  background-size: cover;
  width: 1182px;
  height: 569px;
  border: #ccc solid 1px;
  font-size: 12px;
  margin-top: 109px;
}
.connect {
  height: 56px;
  line-height: 56px;
  font-size: 56px;
  font-family: MicrosoftYaHei;
  color: rgba(81, 85, 88, 1);
}
.connectcontainer {
  padding-top: 137px;
  width: 1182px;
  height: 909px;
  display: flex;
  flex-direction: column;
}
.connectmain {
  width: 1182px;
  height: 1046px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>

